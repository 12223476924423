import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/about-us/hero"
import OurJourney from "./screens/about-us/our-journey"
import ShareOurVision from "./screens/about-us/share-our-vision"

const AboutUs = () => {
  return (
    <React.Fragment>
      <Hero />
      <OurJourney />
      <ShareOurVision />
    </React.Fragment>
  )
}
export default AboutUs

export const Head = () => (
  <Seo
    title="About Us"
    description="Who is Visby Medical? Learn our story when the earliest team members began 
    to explore the idea of a diagnostic test that could be used by anyone, anywhere."
    image="/meta/about-us.jpg"
  />
)
