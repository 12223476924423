import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
	width: 100%;
	height: auto;
	flex: auto;
	display: contents;
`

const StyledHero = styled.section`
  position: relative;
  border-bottom-width: 0;

  ${breakpoint.small`
    display: flex;
  `}

  ${breakpoint.medium`
    max-height: 1040px;
  `}
`

const StyledVideo = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56%;
  background-color: ${colors.white};
  min-height: 260px;
  
    ${breakpoint.wide`
  	  padding-bottom: 44%;
  `}

  .block__content {
    display: ${props => (props.showPlaceholder ? "flex" : "none")};
    flex-wrap: wrap;
    position: absolute;
    text-align: left;
    padding: 0px 30px;
    z-index: 600;
    width: min-content;
    top: 50%;
  	transform: translateY(-50%);

  	${breakpoint.small`
    	padding: 0px 60px;
		top: 44%;
		transform: translateY(-56%);
	  `}

  	${breakpoint.medium`
    	padding: 0px 80px;
	  `}

  h1 {
    font-size: 26px;
    white-space: wrap;
	text-shadow: 4px 3px 5px ${colors.white};

  	${breakpoint.xs`
      font-size: 30px;
	  `}

  	${breakpoint.small`
      font-size: 36px;
	  `}

  	${breakpoint.medium`
      font-size: 42px;
      margin-bottom: 0.6rem;
    `}
	
  	${breakpoint.large`
      font-size: 46px;
	  `}

  }

p {
    font-size: 12px;
    line-height: 1.4em;
	text-shadow: 4px 3px 5px ${colors.white};

    ${breakpoint.small`
      line-height: 1.6em;
    `}

	${breakpoint.medium`
	  font-size: 18px;
	`}

	${breakpoint.large`
	  font-size: 21px;
	`}

	${breakpoint.wide`
	  white-space: nowrap;
	`}
}

 
}

  .video__placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: ${props => (props.showPlaceholder ? "500" : "498")};
    opacity: ${props => (props.showPlaceholder ? "0.99" : "0.22!important")};
    max-height: 1000px;
    tabindex:0;
  }

  iframe {
    width: 90%;
    height: 90%;
    position: absolute;
    display: ${props => (props.showPlaceholder ? "none" : "block")};
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0;
    z-index: 499;
    max-width: 1000px;
    margin: auto;
  }

  .orangeButton {
	  background-color: ${colors.orange};
	  color: ${colors.charcoal}!important;
	  width: 110px;
	  height: 34px;
    margin-top: 12px;
	  display: inline-flex;
	  align-items: center;
	  justify-content: center;
	  padding: 12px 12px;
	  border-radius: 100px;
	  font-size: 11px;
	  line-height: 20px;
	  font-weight: 600;
	  box-sizing: border-box;
	  transition: all .3s ease;

  	${breakpoint.medium`
      width: 130px;
      height: 40px;
      padding: 12px 18px;
      font-size: 14px;
      `}


    &:before {
      content: '';
      border-left: 11px solid ${colors.charcoal};
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      padding-right: 3px;
      transition: all .3s ease;

      ${breakpoint.medium`
      border-left: 13px solid ${colors.charcoal};
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      padding-right: 5px;
      `}
    }
  
    &:hover:before {
      content: '';
      border-left: 11px solid ${colors.white};
      ${breakpoint.medium`
      border-left: 13px solid ${colors.white};
      `}    
    }
  
	  &:hover {
		background-color: ${colors.charcoal};
		color: ${colors.white}!important;
	  }
  }
`

const Video = props => {
  return (
    <StyledVideo
      onClick={props.onClick}
      showPlaceholder={props.showPlaceholder}
    >
      <div className="block__content">
        <h1>
          Who is<br />Visby&nbsp;Medical?
        </h1>
        <p>
          Watch our video to learn more about our mission and story.
        </p>
        <button className="orangeButton"
          onClick={props.onClick}
        >Play Video</button>
      </div>
      <StaticImage
        src="../../../assets/images/about-us/hero-background.jpg"
        width={1440}
        alt="Technicians work in a laboratory."
        className="video__placeholder"
        style={{
          position: "absolute",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      />
      <iframe
        title="About Visby Medical"
        src={
          props.showPlaceholder
            ? "https://player.vimeo.com/video/458027638"
            : "https://player.vimeo.com/video/458027638?autoplay=1"
        }
        frameBorder="0"
        allow="autoplay; fullscreen"
      ></iframe>
    </StyledVideo>
  )
}

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlaceholder: true
    }
  }

  hidePlaceholder = () => {
    if (this.state.showPlaceholder) {
      this.setState({
        showPlaceholder: false
      })
    } else {
      this.setState({
        showPlaceholder: true
      })
    }
  }

  render = () => (
    <StyledHero data-aos="fade">
      <StyledContainer>
        <Video
          showPlaceholder={this.state.showPlaceholder}
          onClick={this.hidePlaceholder}
        />
      </StyledContainer>
    </StyledHero>
  )
}

export default Hero